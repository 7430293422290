import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'

const impactPageload = async (locale, impactParams) => {
  const url = '/api/impact/pageload'
  const result = await fetcher({
    url,
    locale,
    method: 'POST',
    body: impactParams,
  })
  if (!result?.errors && result?.data) {
    const resultData = result?.data

    return resultData
  } else {
    return false
  }
}

export default impactPageload
